import cssVars from 'css-vars-ponyfill';

cssVars({
	variables: {
		'primary': '255, 255, 255',
		'primary-dark': '241, 241, 241',
		'accent': '0, 133, 255',
		'accent-plus': '255, 255, 255',
	},
});
